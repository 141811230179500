

















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Driver from '@/models/Driver';
import { namespace } from 'vuex-class';
import { driverStoreActions, driverStoreMutations } from '@/store/driver.store';
import Company from '@/models/Company';
import { applicationStoreGetter } from '@/store/application.store';
import { UserRoles } from '@/enum/UserRoles';
import Promoter from '@/models/Promoter';

const DriverStore = namespace('driver');
const ApplicationStore = namespace('application');

@Component({
    components: {
        EditDriverComponent: () => import(
            /* webpackChunkName: "EditDriverComponent" */
            '@/components/EditDriver.component.vue'),
        AddTransactionComponent: () => import(
            /* webpackChunkName: "AddTransactionComponent" */
            '@/components/AddTransaction.component.vue'),
        SetCreditComponent: () => import(
            /* webpackChunkName: "SetCreditComponent" */
            '@/components/SetCredit.component.vue')
    }
})
export default class DriverTableComponent extends Vue {
    @ApplicationStore.Getter(applicationStoreGetter.SELECTED_COMPANY)
    public selectedCompany!: Company;

    @DriverStore.Action(driverStoreActions.DELETE)
    public deleteDriverAction!: (payload: { driver: Driver }) => Promise<Driver>;

    @DriverStore.State('driver')
    public driver!: Driver;

    @DriverStore.Mutation(driverStoreMutations.SAVE_DRIVER)
    public saveDriver!: (driver: Driver | null) => void;

    @Prop({ default: () => [] })
    public drivers!: Driver[];

    @Prop({ default: '' })
    public searchValue!: string;

    @Prop({ default: false })
    public loading!: boolean;

    public showEditDialog: boolean = false;
    public showDeleteDialog: boolean = false;
    private showTransactionDialog: boolean = false;
    private showSetCreditDialog: boolean = false;

    /**
     * sorts the list of items, currently the list is only sorted by status
     * @param items
     * @param index
     * @param isDescending
     * @private
     */
    private customSort(items: any[], index: string[], isDescending: boolean[]) {
        items.sort((a: Driver, b: Driver) => {
            switch (index[0]) {
                case 'isVerified':
                    if (isDescending[0]) return a.isVerified ? 1 : -1;
                    else return a.isVerified ? -1 : 1;
                case 'firstName':
                    if (isDescending[0]) return b.firstName.localeCompare(a.firstName);
                    else return a.firstName.localeCompare(b.firstName);
                case 'numberOfRides':
                    if (isDescending[0]) return b.numberOfRides - a.numberOfRides;
                    else return a.numberOfRides - b.numberOfRides;
                case 'passengerCount':
                    if (isDescending[0]) return b.passengerCount - a.passengerCount;
                    else return a.passengerCount - b.passengerCount;
                case 'transactionAmount':
                    if (isDescending[0]) return b.transactionAmount - a.transactionAmount;
                    else return a.transactionAmount - b.transactionAmount;
                case 'lastName':
                    if (isDescending[0]) return b.lastName.localeCompare(a.lastName);
                    else return a.lastName.localeCompare(b.lastName);
                case 'promoter':
                    return this.sortPromoters(a, b, isDescending[0]);
                case 'active':
                    if (isDescending[0]) return a.active ? 1 : -1;
                    else return a.active ? -1 : 1;
                default:
                    return 1;
            }
        });

        return items;
    }

    private sortPromoters(a: Driver, b: Driver, isDescending: boolean): number {
        const promoterB = (b.promoter as Promoter);
        const promoterA = (a.promoter as Promoter);
        if (!promoterA) return 1;
        if (!promoterB) return -1;

        const nameA = `${promoterA.firstName} ${promoterA.lastName}`;
        const nameB = `${promoterB.firstName} ${promoterB.lastName}`;

        return isDescending ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
    }

    public tableHeaders = [{
        text: this.$t('DRIVER.TABLE.FIRSTNAME'),
        value: 'firstName',
        sortable: true
    }, {
        text: this.$t('DRIVER.TABLE.LASTNAME'),
        value: 'lastName',
        sortable: true
    }, {
        text: this.$t('DRIVER.TABLE.PHONE'),
        value: 'phone',
        sortable: false
    }, {
        text: this.$t('DRIVER.TABLE.LICENSE'),
        value: 'driverLicense',
        sortable: false
    }, {
        text: this.$t('DRIVER.TABLE.VERIFIED'),
        value: 'isVerified',
        sortable: true,
        align: 'center'
    }, {
        text: this.$t('COMPANY.TABLE.STATUS'),
        value: 'active',
        sortable: true
    }, {
        text: this.$t('DRIVER.TABLE.NUMBER_OF_RIDES'),
        value: 'numberOfRides',
        sortable: true
    }, {
        text: this.$t('DRIVER.TABLE.NUMBER_OF_USER'),
        value: 'passengerCount',
        sortable: true
    }, {
        text: this.$t('DRIVER.TABLE.CREDIT'),
        value: 'credit',
        sortable: false
    }, {
        text: this.$t('DRIVER.TABLE.TURN_OVER'),
        value: 'transactionAmount',
        sortable: true
    }, {
        text: this.$t('DRIVER.TABLE.PROMOTER'),
        value: 'promoter',
        sortable: true
    }, {
        text: this.$t('DRIVER.TABLE.OPTIONS'),
        value: 'options',
        sortable: false,
        align: 'right'
    }];

    public addTransactionToDriver(driver: Driver) {
        this.saveDriver(driver);
        if (this.$hasRole(UserRoles.ADMIN)) {
            this.showSetCreditDialog = true;
        } else {
            this.showTransactionDialog = true;
        }
    }

    public editSelected(driver: Driver) {
        this.saveDriver(driver);
        this.showEditDialog = true;
    }

    public deleteSelected(driver: Driver) {
        this.saveDriver(driver);
        this.showDeleteDialog = true;
    }

    public async closeDialog(reload: boolean) {
        if (reload) {
            this.$emit('reloadDrivers');
        }

        this.showEditDialog = false;
        this.showTransactionDialog = false;
        this.showSetCreditDialog = false;
        this.saveDriver(null);
    }

    public deleteConfirmed() {
        this.deleteDriverAction({ driver: this.driver });
        this.showDeleteDialog = false;
    }

    public closeDeleteDialog() {
        this.saveDriver(null);
        this.showDeleteDialog = false;
    }

    public get canAddTransaction() {
        return this.$hasAnyRole([UserRoles.ADMIN, UserRoles.MANAGER]);
    }

    private get isAdmin() {
        return this.$hasRole(UserRoles.ADMIN);
    }
}

