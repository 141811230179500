var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"driver-table"},[_c('v-card',{staticClass:"box-standard-shadow table-container py-1"},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"loading":_vm.loading,"items":_vm.drivers,"headers":_vm.tableHeaders,"search":_vm.searchValue,"custom-sort":_vm.customSort,"items-per-page":10},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.firstName)+" ")])]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.lastName)+" ")])]}},{key:"item.isVerified",fn:function(ref){
var item = ref.item;
return [(item.isVerified)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check")])],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-times-circle")])],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.active ? _vm.$t('COMPANY.TABLE.ACTIVE') : _vm.$t('COMPANY.TABLE.INACTIVE'))+" ")])]}},{key:"item.promoter",fn:function(ref){
var item = ref.item;
return [(!item.promoter)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(" "+_vm._s(item.promoter.firstName)+" "+_vm._s(item.promoter.lastName)+" ")])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(_vm.canAddTransaction)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.addTransactionToDriver(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-money-bill-transfer")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editSelected(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1),(_vm.isAdmin)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteSelected(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1):_vm._e()],1)]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [(!item.credit)?_c('div',[_vm._v("0 NAD")]):_c('div',[_vm._v(_vm._s(_vm.$t('DRIVER.CREDIT_AMOUNT', {'value': item.credit})))])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showSetCreditDialog),callback:function ($$v) {_vm.showSetCreditDialog=$$v},expression:"showSetCreditDialog"}},[(_vm.showSetCreditDialog)?_c('set-credit-component',{attrs:{"driver":_vm.driver},on:{"closeDialog":_vm.closeDialog}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showTransactionDialog),callback:function ($$v) {_vm.showTransactionDialog=$$v},expression:"showTransactionDialog"}},[(_vm.showTransactionDialog)?_c('add-transaction-component',{attrs:{"driver":_vm.driver},on:{"closeDialog":_vm.closeDialog}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[_c('edit-driver-component',{attrs:{"driver":_vm.driver},on:{"closeDialog":_vm.closeDialog}})],1),_c('confirm-dialog-component',{attrs:{"showDialog":_vm.showDeleteDialog},on:{"confirmed":_vm.deleteConfirmed,"closed":_vm.closeDeleteDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }